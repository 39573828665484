export const getNumberColorClass = (number: number) => {
    if (number > 0) return 'text-primary_shade dark:text-dark_primary_shade'
    else if (number < 0) return 'text-red_shade dark:text-dark_red_shade'
    else return 'text-gray03 dark:text-dark_gray03'
}

export const getOrderColorClass = (order_type: 'buy' | 'sell' | string) => {
    if (order_type === 'buy') return 'text-primary_shade dark:text-dark_primary_shade'
    else if (order_type === 'sell') return 'text-red_shade dark:text-dark_red_shade'
    else return 'text-gray03 dark:text-dark_gray03'
}

export const getRankBorderColorClass = (number: number) => {
    if (number === 1) return 'border-yellow dark:border-dark_yellow'
    if (number === 2) return 'border-gray05 dark:border-dark_gray05'
    if (number === 3) return 'border-[#EE892B]'
}

export const safeReturn = (value?: any) => {
    if (!value) return undefined

    if (typeof value === 'string') {
        return value.length > 0 ? value : undefined
    } else {
        return value
    }
}
