import BigNumber from 'bignumber.js'

export interface FormatCurrencyNumberOptions {
    number?: number | BigNumber
    currencySymbol?: string
    isPrice?: boolean
    isApproximately?: boolean
    currencyUnicode?: string
    showPostSymbol?: boolean
    isShortForm?: boolean
    isAbsolute?: boolean
    isShowMinusSymbol?: boolean
    isDigit?: boolean
}

interface CurrencyDisplayOption {
    priceDecimalPlace: {
        small: number
        default: number
    }
    balanceDecimalPlace: {
        small: number
        default: number
    }
    circleBalanceDecimalPlace: {
        default: number
    }
}

export const CurrencyDisplayMap = new Map<String, CurrencyDisplayOption>()
CurrencyDisplayMap.set('USD', {
    priceDecimalPlace: {
        small: 8,
        default: 2,
    },
    balanceDecimalPlace: {
        small: 2,
        default: 2,
    },
    circleBalanceDecimalPlace: {
        default: 1,
    },
})
CurrencyDisplayMap.set('EUR', {
    priceDecimalPlace: {
        small: 8,
        default: 2,
    },
    balanceDecimalPlace: {
        small: 2,
        default: 2,
    },
    circleBalanceDecimalPlace: {
        default: 1,
    },
})
CurrencyDisplayMap.set('CNY', {
    priceDecimalPlace: {
        small: 8,
        default: 2,
    },
    balanceDecimalPlace: {
        small: 2,
        default: 2,
    },
    circleBalanceDecimalPlace: {
        default: 1,
    },
})
CurrencyDisplayMap.set('KRW', {
    priceDecimalPlace: {
        small: 2,
        default: 0,
    },
    balanceDecimalPlace: {
        small: 0,
        default: 0,
    },
    circleBalanceDecimalPlace: {
        default: 1,
    },
})
CurrencyDisplayMap.set('JPY', {
    priceDecimalPlace: {
        small: 2,
        default: 0,
    },
    balanceDecimalPlace: {
        small: 0,
        default: 0,
    },
    circleBalanceDecimalPlace: {
        default: 1,
    },
})

CurrencyDisplayMap.set('BTC', {
    priceDecimalPlace: {
        small: 8,
        default: 8,
    },
    balanceDecimalPlace: {
        small: 8,
        default: 8,
    },
    circleBalanceDecimalPlace: {
        default: 4,
    },
})

export function isNumber(value?: any) {
    return typeof value === 'number'
}
