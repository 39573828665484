import * as amplitude from '@amplitude/analytics-browser'
import {amplitudeConfig} from '@constant/config'
import {getCookie} from '@util/cookie'

export type CreateLogType = 'screen' | 'modal' | 'event' | 'loginError' | 'login' | 'error'

export const createLog = (type: CreateLogType, text?: string, option?: any) => {
    const userId = getCookie('userId')
    const event = (eventName: string, option?: any) => {
        if (amplitudeConfig.isSupportLog === true) {
            if (option) {
                amplitude.logEvent(eventName, option as Record<string, any>)
            } else {
                amplitude?.track(eventName)
            }
        }
    }

    switch (type) {
        case 'screen':
            event(`[SCREEN] ${text}`)
            break
        case 'modal':
            event(`[MODAL] ${text}`)
            break
        case 'event':
            event(`[EVENT] ${text}`, option)
            break
        case 'error':
            event(`[ERROR] ${text}`, option)
            break
        case 'loginError':
            amplitude.setUserId('LOT_LOGIN_ERROR')
            event(`[ERROR] ${text}`)
            amplitude.setUserId(userId || 'NONE_REGISTER_USER')
            break
        case 'login':
            const getLoginNetwork = localStorage?.getItem('lastLoginNetwork')

            const getLoginLogText = () => {
                switch (getLoginNetwork) {
                    case 'google':
                        return 'google_login_success'
                    case 'binance':
                        return 'binance_login_success'
                    case 'coinbase':
                        return 'coinbase_login_success'
                    case 'facebook':
                        return 'facebook_login_success'
                    case 'apple':
                        return 'apple_login_success'
                    case 'kakaotalk':
                        return 'kakao_login_success'
                }
            }
            if (getLoginNetwork !== 'email') {
                event(`[LOGIN] ${getLoginLogText()}`)
            }
            localStorage.removeItem('lastLoginNetwork')
            break
    }
}
