import {NICKNAME_MAX_LENGTH, NICKNAME_MIN_LENGTH} from '@constant/auth'

const emailVaild = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i
const phoneVaild = /^[0-9]{1,3}[-\s\.]?[0-9]{3,4}[-\s\.]?[0-9]{4,6}$/i
const nicknameVaild = /^[A-Za-z0-9\[\]_]*$/

export const hashTagVaild = /#([a-zA-Z0-9]+)/g
export const LINE_BRACKET_EXP = /(\n\s*\n\s*)+|\s*\n\s*$/g
export const HTML_TAG_EXP = /(<([^>]+)>)/gi
export const URL_EXP =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g

export const PASSWORD_CHAR_EXP = /^[A-Za-z0-9-!#$%&@'*+./=?^_,`{(|)}~]*$/
export const PASSWORD_CHECK_WEEK_EXP = /^[A-Za-z0-9-!#$%&@'*+./=?^_,`{(|)}~]{8,}$/
export const PASSWORD_CHECK_MEDIUM_EXP = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
export const PASSWORD_CHECK_STRONG_EXP =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[-!#$%&@'*+./=?^_,`{(|)}~])[A-Za-z\d-!#$%&@'*+./=?^_,`{(|)}~]{8,}$/

export const emailValidation = (email: string) => {
    return email.match(emailVaild)
}

export const phoneValidation = (phone: string) => {
    return phone.match(phoneVaild)
}

export const nameValidation = (name: string) => {
    if (!name || name.length > NICKNAME_MAX_LENGTH || name.length < NICKNAME_MIN_LENGTH) {
        return false
    }
    return name.match(nicknameVaild)
}

export const urlValidation = (url: string) => {
    if (!url) return false
    return URL_EXP.test(url)
}

export const getHashtag = (text: string) => {
    if (!text) return []
    const matches = text.match(hashTagVaild)
    if (!matches) return []
    return matches?.map(tag => tag.replace('#', ''))
}

export const getURL = (text: string) => {
    if (!text) return undefined
    const matches = text.match(URL_EXP)
    if (!matches) return undefined
    return matches?.at(0)
}

export const removeHTMLTag = (text: string) => {
    return text.replace(HTML_TAG_EXP, '')
}
export const removeLineBreak = (text: string) => {
    return text.replace(LINE_BRACKET_EXP, '')
}
