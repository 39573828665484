export const cutMidString = (str?: string, padding: number = 3): string => {
    if (!str) return ''
    if (padding * 2 >= str.length) {
        return str
    }
    return str.substring(0, padding) + '...' + str.substring(str.length - padding)
}

export const cutEndString = (str?: string, length: number = 10): string => {
    if (!str) return ''
    if (length >= str.length) {
        return str
    }
    return str.substring(0, length) + '...'
}

export const equalString = (str1?: string, str2?: string): boolean => {
    if (!str1 || !str2) return false

    return str1?.toLowerCase() === str2?.toLowerCase()
}

export const isEmpty = (text?: string): boolean => {
    if (!text) return true
    else if (text?.length === 0) return true
    else return false
}

export const isNotEmpty = (text?: string): boolean => {
    return !isEmpty(text)
}
