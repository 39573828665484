export const addImageWebpQuery = (source?: any, width?: number, height?: number, isResize: boolean = true) => {
    if (!source) return

    if (typeof source === 'string') {
        let url = source

        if (url?.startsWith('https://cache.leagueoftraders.io')) {
            return isResize
                ? `${url}?type=webp&width=${Number(width) < 200 ? 200 : width}&quality=100`
                : `${url}?type=webp&quality=100&action=fit`
        } else return url
    } else {
        return source
    }
}
